import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import Signin from './login/Signin'; // Importando Signin.js
import Signup from './login/Signup'; // Importando Signup.js
import './App.css'; // Para customizações adicionais de CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import content from './settings/lang-config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

/* global adsbygoogle */

function App() {
  const [language, setLanguage] = useState('en');
  const [showSignin, setShowSignin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);  
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const adElements = document.querySelectorAll('ins.adsbygoogle');
    adElements.forEach((el) => {
      if (!el.hasAttribute('data-ad-status')) {
        try {
          (adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error('AdSense error: ', e);
        }
      }
    });
  }, []);
  
  const handleSignout = () => {
    const auth = getAuth();
    auth.signOut();
  };

  const handleShowSignin = () => setShowSignin(true);
  const handleCloseSignin = () => setShowSignin(false);

  return (
    <div className="container-fluid p-0" style={{ backgroundColor: '#1a1845', color: '#1a1845' }}>
      {/* Cabeçalho com Navbar */}
      <Navbar bg="dark" variant="dark" expand="lg" className="px-3">
        <Navbar.Brand href="#">
          <div className="d-flex align-items-center">
            <img src="./logo_cropped.png" alt="Logo" className="me-3" style={{ marginLeft: '10px', height: '50px' }} />
            <img src="./logomarca_cropped.png" alt="Logomarca" className="me-3" style={{ marginLeft: '-10px', height: '25px' }} />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">

          {user ? (
            <Button variant="danger" onClick={handleSignout}>
              {content[language].signOut}
            </Button>
          ) : (
            <Button variant="primary" onClick={() => window.open('https://portal.langepto.com', '_blank')}>
              {content[language].signIn}
            </Button>
          )}

            <Button
              variant="link"
              onClick={() => setLanguage('pt')}
              style={{ margin: '0 5px' }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg"
                alt="Português"
                style={{ width: '30px', height: '30px' }}
              />
            </Button>
            <Button
              variant="link"
              onClick={() => setLanguage('en')}
              style={{ margin: '0 5px' }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                alt="English"
                style={{ width: '30px', height: '30px' }}
              />
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
      {/* <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-6123794574040714"
        data-ad-slot="4453890580"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}

      <div
        className="banner d-flex flex-column justify-content-center align-items-center text-center position-relative"
        style={{
          width: '100%',
          height: 'calc(100vh - 200px)',
          backgroundImage: 'url(./banner.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#ffffff',
        }}
      >
        {/* Máscara escura */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            position: 'relative',
            zIndex: 2,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <h1
            style={{
              padding: '0 10px',
              fontSize: '3.5rem',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 'bold',
              marginBottom: '15px',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            {user ? content[language].loggedTitle : content[language].title}
          </h1>
          {/* <p
            style={{
              padding: '0 10px',
              fontSize: '1.25rem',
              fontFamily: 'Arial, sans-serif',
              maxWidth: '600px',
              marginBottom: '25px',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
            }}
          >
            {user ? content[language].loggedSubtitle : content[language].subtitle}
          </p> */}

          {!user && (
            <>
              <Button
                variant="success"
                style={{
                  fontSize: '1.2rem',
                  padding: '10px 30px',
                  marginBottom: '25px',
                  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.3)',
                }}
                onClick={() => window.open('https://portal.langepto.com', '_blank')}
              >
                {content[language].signUp}
              </Button>

              <small
                style={{
                  padding: '0 10px',
                  fontSize: '0.85rem',
                  fontFamily: 'Arial, sans-serif',
                  maxWidth: '600px',
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                }}
              >
                {content[language].freeNote1}
              </small>

              <small
                style={{
                  padding: '0 10px',
                  fontSize: '0.85rem',
                  fontFamily: 'Arial, sans-serif',
                  maxWidth: '600px',
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                }}
              >
                {content[language].freeNote2}
              </small>
            </>
          )}
        </div>
      </div>

      {/* Três Colunas Centralizadas */}
      <div style={{ backgroundColor: '#0b0a2e', color: '#ffffff', paddingTop: '25px' }}>
        <div className="container my-5">
          <div className="row justify-content-center">
            {content[language].thumbnails.map((text, index) => (
              <div key={index} className="col-12 col-md-4 d-flex flex-column align-items-center mb-4">
                <img src={`./thumbnail${index + 1}.webp`} alt={`Thumbnail ${index + 1}`} className="img-fluid mb-2" style={{ width: '300px', height: 'auto' }} />
                <p style={{ width: '300px', textAlign: 'justify', color: '#ffffff' }}>{text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Input de E-mail */}
      <div
        className="email-notification d-flex flex-column align-items-center text-center"
        style={{ color: '#ffffff', marginBottom: '60px' }}
      >
        <p style={{ fontSize: '1.05rem', fontFamily: 'Arial, sans-serif' }}>
          {content[language].emailPrompt}
        </p>
        <div className="d-flex" style={{ gap: '10px' }}>
          <input
            type="email"
            placeholder={content[language].emailPlaceholder}
            className="form-control"
            style={{ width: '200px', padding: '10px' }}
          />
          <Button variant="success" style={{ padding: '10px 20px' }}>Enviar</Button>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer text-center py-3" style={{ backgroundColor: '#0b0a2e', color: '#f0f0f0', bottom: 0, width: '100%' }}>
        <small>GePTo © 2024 </small>
      </footer>

      {/* Modais de Sign-in e Sign-up */}
      <Signin 
          show={showSignin} 
          handleClose={() => setShowSignin(false)} 
          handleShowSignup={() => {
            setShowSignin(false);
            setShowSignup(true);
          }} 
          content={content} 
          language={language} 
        />

        <Signup 
          show={showSignup} 
          handleClose={() => setShowSignup(false)} 
          content={content} 
          language={language} 
        />

    </div>
  );
}

export default App;
