const content = {
    pt: {
      title: 'Domine o Inglês com o GePTo.',
      loggedTitle: "Seja bem-vindo(a) à nova era da aprendizagem autônoma",
      subtitle: 'Um tutor Autônomo, Inteligente e Grátis* e que chegará em breve para alavancar seu idioma.',
      loggedSubtitle: "Em breve te notificaremos para fazer o uso do nosso aplicativo. Não esqueça de checar seu e-mail e verificá-lo.",
      signIn: 'Logar',
      signOut: 'Sair',
      signInTitle: 'Entre na sua conta',
      password: 'Senha',
      signUp: 'Inscreva-se',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      email: 'E-mail',
      phone: 'Telefone',
      phonePlaceholder: '+5511981573432',
      confirmPassword: 'Confirme a Senha',
      passwordMismatch: 'As senhas não coincidem.',
      firstNamePlaceholder: 'Digite seu nome',
      lastNamePlaceholder: 'Digite seu sobrenome',
      passwordPlaceholder: 'Digite sua senha',
      confirmPasswordPlaceholder: 'Confirme sua senha',      
      freeNote1: '* A versão grátis possui uso limitado.',
      freeNote2: '* Para uso do App nenhum cartão de crédito é necessário.',
      emailPrompt: 'Quer saber quando o APP for lançado?',
      emailPlaceholder: 'Digite seu e-mail',
      thumbnails: [
        'Totalmente autônomo, o GePTo conversa sempre em inglês, proporcionando uma prática natural e contínua para melhorar sua fluência.',
        'O GePTo oferece desafios interativos que ajudam a praticar e melhorar seu vocabulário, tornando o aprendizado mais envolvente e eficaz.',
        'Faça parte da nossa comunidade, onde você pode postar dúvidas e interagir com outros alunos, tornando o aprendizado mais colaborativo e motivador.',
      ],
      forgotPassword: 'Esqueci a senha',
      createAccount: 'Criar uma nova conta',
    },
    en: {
      title: 'Master English with GePTo.',
      loggedTitle: "Welcome to the new era of autonomous learning",
      subtitle: 'An Autonomous, Smart, and Free* tutor that will soon be available to boost your language skills.',
      loggedSubtitle: "We will notify you soon to use our app. Don't forget to check and verify your email.",
      signIn: 'Sign-in',
      signInTitle: 'Sign in to your account',
      signUp: 'Sign-up',
      signOut: 'Leave',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      password: 'Password',
      phonePlaceholder: '+5511981573432',
      confirmPassword: 'Confirm Password',
      passwordMismatch: 'Passwords do not match.',
      firstNamePlaceholder: 'Enter your first name',
      lastNamePlaceholder: 'Enter your last name',
      emailPlaceholder: 'Enter your email',
      passwordPlaceholder: 'Enter your password',
      confirmPasswordPlaceholder: 'Confirm your password',
      freeNote1: '* The free version has limited usage.',
      freeNote2: '* No credit card is required to use the app.',
      emailPrompt: 'Want to know when the APP will be released?',
      thumbnails: [
        'Fully autonomous, GePTo always converses in English, providing natural and continuous practice to improve your fluency.',
        'GePTo offers interactive challenges to help you practice and improve your vocabulary, making learning more engaging and effective.',
        'Join our community, where you can post questions and interact with other students, making learning more collaborative and motivating.',
      ],
      forgotPassword: 'Forgot password',
      createAccount: 'Create a new account',
    },
  };

  export default content;