import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAkNiPWMWGONHsWaPb3jIhBNQaZ2Uvu_qY",
  authDomain: "aigpt-86e88.firebaseapp.com",
  projectId: "aigpt-86e88",
  storageBucket: "aigpt-86e88.appspot.com",
  messagingSenderId: "216532667822",
  appId: "1:216532667822:web:1ed25e4fac6ee5815e0c3f",
  measurementId: "G-V2VH9SFJNN"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function Signin({ show, handleClose, handleShowSignup, content, language }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCreateAccount = () => {
    handleClose(); // Fechar o modal de Signin
    handleShowSignup(); // Abrir o modal de Signup
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleClose();
      window.open('https://portal.langepto.com', '_blank');
    } catch (error) {
      console.error("Error logging in:", error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{content[language].signInTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
        {user ? (
          <div>
            <p>{content[language].welcome}, {user.email}</p>
            <Button variant="danger" onClick={() => auth.signOut()} className="mt-3">
              {content[language].signOut}
            </Button>
          </div>
        ) : (
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="email">
              <Form.Label>{content[language].email}</Form.Label>
              <Form.Control 
                type="email" 
                name="email" 
                placeholder={content[language].emailPlaceholder} 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required 
              />
            </Form.Group>
            <Form.Group controlId="password" className="mt-3">
              <Form.Label>{content[language].password}</Form.Label>
              <Form.Control 
                type="password" 
                name="password" 
                placeholder="**********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required 
              />
            </Form.Group>
            <Button variant="success" type="submit" className="mt-3">
              {content[language].signIn}
            </Button>
          </Form>
        )}
        {!user && (
          <>
            <div className="mt-3">
              <a href="#" style={{ color: '#ffffff' }}>{content[language].forgotPassword}</a>
            </div>
            <div className="mt-1">
              <a href="#" onClick={handleCreateAccount} style={{ color: '#ffffff' }}>{content[language].createAccount}</a>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default Signin;
