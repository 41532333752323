import React, { useState } from 'react';
import { Modal, Button, Form,Spinner } from 'react-bootstrap';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';

function Signup({ show, handleClose, content, language }) {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    telefone: '',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError(content[language].passwordMismatch);
      return;
    }
  
    const auth = getAuth();
    setLoading(true);
    try {
      // Cria o usuário no Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
  
      // Envia os dados para a API personalizada
      await axios.post('https://api.langepto.com/account/user/create', {
        nome: formData.firstName,
        sobrenome: formData.lastName,
        email: formData.email,
        telefone: formData.telefone,
      });
  
      // Limpar os dados do formulário e fechar o modal
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        telefone: '',
      });
      handleClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{content[language].signUp}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="firstName">
              <Form.Label>{content[language].firstName}</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder={content[language].firstNamePlaceholder}
                required
              />
            </Form.Group>
            <Form.Group controlId="lastName" className="mt-3">
              <Form.Label>{content[language].lastName}</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder={content[language].lastNamePlaceholder}
                required
              />
            </Form.Group>
            <Form.Group controlId="email" className="mt-3">
              <Form.Label>{content[language].email}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={content[language].emailPlaceholder}
                required
              />
            </Form.Group>
            <Form.Group controlId="telefone" className="mt-3">
              <Form.Label>{content[language].phone}</Form.Label>
              <Form.Control
                type="tel"
                name="telefone"
                value={formData.telefone}
                onChange={handleChange}
                placeholder={content[language].phonePlaceholder}
              />
            </Form.Group>
            <Form.Group controlId="password" className="mt-3">
              <Form.Label>{content[language].password}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="************"
                required
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" className="mt-3">
              <Form.Label>{content[language].confirmPassword}</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="************"
                required
              />
            </Form.Group>
            {error && <div className="text-danger mt-3">{error}</div>}
            <Button variant="success" type="submit" className="mt-3">
              {content[language].signUp}
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
  
}

export default Signup;
